// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;
$enable-negative-margins: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
$body-color: #212529;
$body-bg: #ffffff;
$border-radius: 5px;

$primary: #0065FA;
$secondary: #626273;
$success: #44B527;
$info: #F8B72E;
$warning: #F8B731;
$danger: #D32F47;
$light: #f6f6f8;
$dark: #212529;
$link-color: #0064F7;
$white: #fff;


// Accordion
$accordion-padding-y:                     1rem;
$accordion-padding-x:                     1.25rem;
$accordion-color:                         $body-color;
$accordion-bg:                            $body-bg;
$accordion-border-width:                  1px;
$accordion-border-color:                  #d4d4d7;
$accordion-border-radius:                 $border-radius;
$accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width);

$accordion-body-padding-y:                $accordion-padding-y;
$accordion-body-padding-x:                $accordion-padding-x;

$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;
$accordion-button-color:                  #b1b1b9;
$accordion-button-bg:                     $light;
$accordion-button-active-bg:              $body-bg;
$accordion-button-active-color:           $body-color;

$accordion-button-focus-border-color:     none;
$accordion-button-focus-box-shadow:       none;


$accordion-icon-width:                    1.25rem;
$accordion-icon-color:                    $accordion-color;
$accordion-icon-active-color:             $body-color;
$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                rotate(-180deg);


$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");


$navbar-toggler-padding-x:          .35rem;
$navbar-light-toggler-border-color: transparent;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

$breadcrumb-divider-color:          #a7a7B0;
$breadcrumb-active-color:           #212529;
$breadcrumb-item-padding-x:         1rem;
$breadcrumb-divider:                url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#A7A7B0'/%3E%3C/svg%3E");


$pagination-padding-y:              .375rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;


$pagination-border-width:           0;
$pagination-border-radius:          $border-radius;
$pagination-margin-start:           -$pagination-border-width;
$pagination-border-color:           none;

$pagination-focus-color:            #212529;
$pagination-focus-bg:               #f6f6f8;
$pagination-focus-box-shadow:       none;
$pagination-focus-outline:          0;

$pagination-hover-color:            #71707c;
$pagination-hover-bg:               #f6f6f8;
$pagination-hover-border-color:     none;

$pagination-active-color:           #151921;
$pagination-active-bg:              #f6f6f8;
$pagination-active-border-color:    none;

$pagination-disabled-color:         #b1b1b9;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  none;

$pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$list-group-border-width:           0;

  // Required

@import "../node_modules/mdb-ui-kit/src/scss/free/variables";
@import "../node_modules/mdb-ui-kit/src/scss/pro/variables";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/helpers/position";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
@import "bootstrap/scss/placeholders";
@import "../node_modules/rfs/scss";
@import "../node_modules/mdb-ui-kit/src/scss/mdb.core";
@import "../node_modules/mdb-ui-kit/src/scss/pro/_lightbox";
@import "../node_modules/mdb-ui-kit/src/scss/free/_ripple";

// Helpers
// @import "helpers";

// Utilities
@import "bootstrap/scss/utilities/api";


//
// Custom styles
//

.main-accordion-button {
  $accordion-button-icon:         none;
  $accordion-button-active-icon:  none;
}

.faq-accordion-button {
  $accordion-button-icon:         none;
  $accordion-button-active-icon:  none;
}

.footer-link {
  font-size: 18px;
  text-decoration: none;

}
.accordion-no-padding {
  padding: 0;
}

.accordion * {outline: 0 !important;
box-shadow: none;}

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths


// Source mixin
@mixin make-container($padding-x: $container-padding-x) {
  width: 70%;
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  margin-right: auto;
  margin-left: auto;

}

// Usage
.inner-container {
  @include make-container();
}


// Source mixins

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`

// Responsive dydžiai pagal dizaino sistemą
html {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  min-height: 440px;
  overflow-y: auto;
}
body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}
.discoverProducts {
  font-size: 22px;
}
#indexLogin {
  width: 100%;
}
#productImageWide {

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: auto;
}
#productImage {

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 150px;
}
.understrike {
  background-image: url("../img/landingUnderlineWide.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 0.5rem;
}
.landing-title {
  font-size: 25px;
  font-weight: bold;
}
.landing-secondary {
  font-size: 18px;
}
.h1 {
  font-size: 25px;
  font-weight: bold;
}
.h2 {
  font-size: 20px;
  font-weight: bold;
}
.h3 {
  font-size: 20px  !important;;
  font-weight: bold;
  margin-bottom: 0;
}
.payment-header {
  font-size: 25px;
}
.payment-title {
  font-size: 17px;
}
.navbar.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
#navigationMobile {
  z-index: 1001;
}

.fixie {
  padding-top: 77px;
}
#loginLeft {
  height: auto;
  min-height: 100vh;
  background-color: transparent;
  background-size: cover;
  background-position: top center;
  background-image: url("../img/loginDecorationBg.svg");
  background-repeat: no-repeat;
}
#loginRight {
  height: 100vh;
  min-height: 440px;
  background-position: bottom center;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../img/loginBgIconsTablet.svg");
}
.list-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  width: 100%;
  background-position: center center;
}
.mobile-background {
  background-color: #fff;
}
.recentlyviewed {
  font-size: 20px;
  font-weight: bold;
  color: #212529;
  background-color: #F8B72E;
}
.blogpost-title {
  font-size: 20px;
  font-weight: bold;
  color: #212529;
  background-color: #F8B72E;
}
@include media-breakpoint-up(sm) {

  .landing-title {
    font-size: 25px;
    font-weight: bold;
  }
  .h1 {
    font-size: 25px;
    font-weight: bold;
  }
  .h2 {
    font-size: 25px;
    font-weight: bold;
  }
  .img-thumbnail.active {
    box-shadow: none;
  }

}
@include media-breakpoint-up(md) {
  .discoverProducts {
    font-size: 30px;
  }
  .blogpost-title {
    font-size: 30px;
  }
  .recentlyviewed{
    font-size: 25px;
  }
  .list-image {
    height: 193px;
    width: 348px;

  }
  #productImage {
    height: 230px;
  }
  .understrike {
    background-image: url("../img/landingUnderlineWide.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 0.7rem;
  }


  .landing-title {
    font-size: 37px;
    font-weight: bold;
  }
  .h1 {
    font-size: 35px;
    font-weight: bold;
  }
  .h2 {
    font-size: 30px;
    font-weight: bold;
  }
  .h3 {
    font-size: 25px  !important;;
    font-weight: bold;
  }
  .payment-header {
    font-size: 30px;
  }
  .payment-title {
    font-size: 28px;
  }
  .img-thumbnail.active {
    box-shadow: none;
  }

}
@include media-breakpoint-up(lg) {
  #indexLogin {
    width: auto;
  }
  .recentlyviewed{
    font-size: 30px;
  }
  .discoverProducts {
    font-size: 40px;
  }
  .list-image {

    height: 307px;
    width: 471px;

  }

.mobile-background {
  background-color: transparent;
}
  .landing-secondary {
    font-size: 30px;
  }
  .understrike {
    background-image: url("../img/landingUnderlineWide.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 1rem;
  }
  #loginLeft {
    height: auto;
    min-height: auto;
    background-color: transparent;
    background-size: auto;
    background-position: top center;
    background-image: url("../img/loginDecorationBg.svg");
    background-repeat: no-repeat;
  }
  #loginRight {
    height: 100vh;
    background-position: center;
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../img/loginBgIcons.svg");
  }
  .accordion-button-instructions {
    pointer-events: none;
    &:not(.collapsed) {

      &::after {
        content: none;
      }
    }
  }
  .punktai-hover.border-bottom.border-1 {
    border-width: 0px !important;
  }
  .img-thumbnail:hover {
    pointer-events: auto;
    cursor: pointer;
  }
  .bg-light.rounded.gallery {
    background-color: transparent !important;
  }
  .fixie {
    padding-top: 0px;
  }
  .landing-title {
    font-size: 51px;
    font-weight: bold;
  }
  .h1 {
    font-size: 40px;
    font-weight: bold;
  }
  .h3 {
    font-size: 30px !important;
    font-weight: bold;
  }
  .navbar.fixed {
    position: relative;
    min-width: 100%;
    top: 0;
    left: 0;
  }
  .img-thumbnail.active {
    border-color: rgba(21, 124, 251, 1);
    border-width: 2px;
  }
}

@include media-breakpoint-up(xl) {
  #loginLeft {
    height: auto;
    min-height: 100vh;
    background-color: transparent;
    background-size: auto;
    background-position: top center;
    background-image: url("../img/loginDecorationBg.svg");
    background-repeat: no-repeat;
  }
  .indexBg {
    background-image: url("../img/indexBg.svg");
    background-position: top center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
  }

}
@include media-breakpoint-up(xxl) {

}

// Naudojimas

// Example: Hide starting at `min-width: 0`, and then show at the `sm` breakpoint
.custom-class {
  display: none;
}
@include media-breakpoint-up(sm) {
  .custom-class {
    display: block;
  }
}

@import "icon-list";

// Basic nustatymai


p {
  font-size: 18px;
}
.contacts {
  font-size: 17px;
}
.bg-light {
  background-color: #F6F6F8;
}
.set-discount {
  text-decoration: line-through;
  color: #D32F47;
}
// Style Bootstrap icons
.bi {
  fill: currentColor;
}
// spalvos ir mygtukų manipuliacijai reikalingi variables

.grayscale {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.hideonOpen {
  display: block;
}

// Pakeičia bankų logo spalvas

#paymentButton1:not(.collapsed){
  .grayscale {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }

}
#paymentButton2:not(.collapsed){
  .grayscale {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
}
#paymentButton3:not(.collapsed){
  .grayscale {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
}

//
// Ar rodyti mygtuką "KEISTI"

#firstbutton:not(.collapsed){
  .hideonOpen {
    display: none;
  }
}
#secondbutton:not(.collapsed){
  .hideonOpen {
    display: none;
  }
}
#thirdbutton:not(.collapsed){
  .hideonOpen {
    display: none;
  }
}
#fourthbutton:not(.collapsed){
  .hideonOpen {
    display: none;
  }
}

// Faq accordion buttons
.accordion-button-faq {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: #212529;
  text-align: left; // Reset button style
  background-color: #fff;
  border: 0;
  @include border-radius(0.25rem);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: inherit;
    background-color: #fff;
    box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

    &::after {
      margin-left: auto;
      background-image: none;
      font-family: 'FontAwesome';
      width: auto;
      height: auto;
      color: #F8B731;
      content: "\f068";
      transform: rotate(-360deg);
    }
  }

  // Accordion icon
  &::after {
    margin-left: auto;
    font-family: 'FontAwesome';
    width: auto;
    height: auto;
    color: #F8B731;
    content: "\f067";
    background-image: none;
    transition: transform 0.2s ease-in-out;;
  }
  &:hover {
    z-index: auto;
  }
  &:focus {
    z-index: auto;
    border-color: inherit;
    outline: 0;
    box-shadow: none;
  }
}
// Main accordion buttons
.accordion-button-main {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: $accordion-button-color;
  text-align: left; // Reset button style
  background-color: $accordion-button-bg;
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: inherit;
    background-color: inherit;
    box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

    &::after {
      content: none;
    }
  }

  // Accordion icon
  &::after {
    content: none;
  }
  &:hover {
    z-index: auto;
  }
  &:focus {
    z-index: auto;
    border-color: inherit;
    outline: 0;
    box-shadow: none;
  }
}

.accordion-button-instructions {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: $accordion-button-color;
  text-align: left; // Reset button style
  background-color: $accordion-button-bg;
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: inherit;
    background-color: inherit;
    box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

    &::after {
    transform: rotate(0deg);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }
  &:hover {
    z-index: auto;
  }
  &:focus {
    z-index: auto;
    border-color: inherit;
    outline: 0;
    box-shadow: none;
  }
}
button:disabled { pointer-events: none };

// Modal item

.modal-item {
  border: 1px solid #efeff0;
}
.modal-item:hover {
  background: whitesmoke;
}
.modal-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.modal-item:not(:first-of-type) {
  border-top: 0;

}
.modal-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.btn  {
  font-size: 1rem;
}
.btn-lg {
  font-size: 1.25rem;
}
.navbar-light .navbar-toggler {
  color: #F6F6F8;
}

#punktas1, #punktas2, #punktas3 , #punktas4, #punktas5, #punktas6 {
  span {
    color: #626273;
  }
}
#punktas1:hover, #punktas2:hover, #punktas3:hover , #punktas4:hover, #punktas5:hover, #punktas6:hover {
  span {
    color: #212529;
  }
}
#punktai:hover #punktas1:not(:hover){
  span {
   color: #A7A7B0;
 }
}
#punktai:hover #punktas2:not(:hover){
  span {
    color: #A7A7B0;
  }
}
#punktai:hover #punktas3:not(:hover){
  span {
    color: #A7A7B0;
  }
}
#punktai:hover #punktas4:not(:hover){
  span {
    color: #A7A7B0;
  }
}
#punktai:hover #punktas5:not(:hover){
  span {
    color: #A7A7B0;
  }
}
#punktai:hover #punktas6:not(:hover){
  span {
    color: #A7A7B0;
  }
}

.read-more {

  a {
    color: #212529;
  }

}

.read-more:hover {

  a {
    color: #0065FA;
  }
}
body.modal-open {
  padding-right: 0 !important;
}
.yellowstar {
  color: #FFCC00;
}
.page-item.active .page-link {
  z-index: 1;
  font-weight: 700;
}
.clientPicture {
  height: 55px;
  background-color: #ffffff;
  background-image: url("assets/img/placeholderPic.svg");

}

.errorMsg {
  color: $danger;
  background-color: #F9E5E8;
}
.confirmMsg {
  color: #EFB02F;
  background-color: #FFF6E0;
}
/* Combining media query expressions */
@media screen and (max-height: 570px) {
  .hideonShort {
  display: none !important;
  }
}
@media screen and (max-height: 500px) {
  .hideonShortWide {
    display: none !important;
  }
}
@media screen and (min-height: 1000px) {
  #loginLeft {
    height: auto;
    min-height: 100vh;
    background-color: transparent;
    background-size: cover;
    background-position: bottom center;
    background-image: url("../img/loginDecorationBg.svg");
    background-repeat: no-repeat;
  }
}
@media screen and (max-height: 730px) {
  #loginLeft {
    height: auto;
    min-height: 100vh;
    background-color: transparent;
    background-size: cover;
    background-position: top center;
    background-image: unset;
    background-repeat: no-repeat;
  }
}

@media screen and (max-height: 440px){
  html{
    height: 440px;
    overflow: auto;
  }
}

section.faq-background {
  background-image: url("assets/img/FAQbackground.svg");
  background-size: cover; /* Resize the background image to cover the entire container */
  background-repeat: no-repeat; /* Do not repeat the image */
  display: block;
  height: auto;
}
.contact-badge {
  font-size: 25px;
  color: #212529;
  background-color: #F8B72E;
}

a {
  .list-group-item:hover {
    background-color: #F6F6F8;
  }
}
.recommended {
  border-radius: $border-radius;
  color: #ffffff;
  background-color: #44B527;
  text-align: center;
  font-size: 20px;
  width: auto;
}
.popular {
  border-radius: $border-radius;
  color: #ffffff;
  background-color: #F8B731;
  text-align: center;
  font-size: 20px;
  width: auto;
}

.set-selection:hover {
  box-shadow:  0 .5rem 1rem rgba($black, .15);
}
.list-group-item {
  color: #212529;
}
#meniuToggler {
    .navbar-toggler-icon {
      background-image: unset;
    }
  }
.indexBg {
  background-image: url("../img/indexBg.svg");
  background-position: top center;
  background-repeat: no-repeat;
}
.product-link-wide.active {
  border-left: 3px solid #0065FA !important;
  h2 {
    color: #0065FA;
  }
}
.product-link-wide:hover {
  h2 {
    color: #0065FA;
  }
}
.bg-white.rounded.prod:hover {
  box-shadow:  0 .5rem 1rem rgba($black, .15);
}
.blogLink:first-of-type {
  border-bottom-left-radius: 0 ;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.blogLink:last-of-type {
  border-bottom-left-radius: 5px ;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0 ;
  border-top-right-radius: 0;
}
.blogLink {
  border: 1px solid #e0e0e0 ;
}
.blogLink:not(:first-of-type){
  border-top: 0 !important;
}

.badge.bg-badge-danger.tail::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute ;
  margin-left: -17px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right:8px solid #F9E5E8;
}
.badge.bg-badge-success.tail::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute ;
  margin-left: -17px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right:8px solid #D9F0D3;
}
